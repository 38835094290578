.pointer {
    cursor: pointer;
}

.hover:hover {
    background-color: rgba(0, 64, 252, 0.1);
}

.selected {
    background-color: rgba(255, 237, 79, 0.2);
}

.nav-link.active {
    text-decoration: underline;
}
